import React, { useContext, useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroSection from "@src/components/partials/newconundrums/HeroSection";
import VideoSliderSection from "@src/components/partials/newconundrums/VideoSliderSection";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import SEO from "@src/components/SEO";
import FeaturesSection from "@src/components/partials/shared/FeaturesSection";
import { Space } from "@src/components/nessie-web";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const ConundrumsPage = () => {
  const data = useStaticQuery(graphql`
    {
      teacher: file(name: { eq: "teacher-basics" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      directus {
        conundrums_page {
          space_dog_image {
            id
            filename_disk
          }
          video_cover_image_url
          youtube_id
          videos: video
          astranova_image {
            filename_disk
          }
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      conundrums_page: { space_dog_image, videos, astranova_image, video_cover_image_url, youtube_id },
    },
  } = data;
  const t = useContext(TranslationContext);

  const [showPrizeBanner, setShowPrizeBanner] = useState(false);
  const bannerRef = useRef(null);

  const intersectionObserverCallBack = (entries) => {
    const [entry] = entries;
    setShowPrizeBanner(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4,
    };

    const observer = new IntersectionObserver(intersectionObserverCallBack, options);
    if (bannerRef.current) observer.observe(bannerRef.current);
    return () => {
      if (bannerRef.current) observer.unobserve(bannerRef.current);
    };
  }, [bannerRef]);

  const HeroSectionProps = {
    hero_title: "directus.conundrums_page.hero_title",
    hero_description: "directus.conundrums_page.hero_description",
    space_dog_image,
    space_dog_alt: "directus.conundrums_page.space_dog_alt",
    video_cover_image_url,
    youtube_id,
  };

  return (
    <>
      <SEO
        title="ClassDojo Conundrums"
        description="We teamed up with Astra Nova to create Conundrums! This learning series with Activities introduces your students to the idea that not all problems have a clear right answer."
        image="https://static.classdojo.com/img/conundrums/conundrums-share.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo Conundrums",
          image: "https://static.classdojo.com/img/conundrums/conundrums-share.jpg",
          description:
            "We teamed up with Astra Nova to create Conundrums! This learning series with Activities introduces your students to the idea that not all problems have a clear right answer.",
        }}
      />
      <div ref={bannerRef}>
        <HeroSection {...HeroSectionProps} />
        <VideoSliderSection videos={videos} />
      </div>
      <Space size="l" />
      <FeaturesSection
        lightDark
        transparency
        inverted
        contentData={{
          features: [
            {
              img: astranova_image,
              title: t.translate("directus.conundrums_page.astranova_title"),
              description: t.translate("directus.conundrums_page.astranova_description"),
            },
          ],
        }}
      />
    </>
  );
};

export default ConundrumsPage;
